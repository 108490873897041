<template>
  <v-container fluid class="ma-0 pa-0">
    <v-row class="ma-0 pa-0">
      <v-col cols="12" class="ma-0 pa-0">
        <v-card flat tile
                :width="$vuetify.breakpoint.width"
                :min-height="$vuetify.breakpoint.height - 192"
                class="presize-padding all"
                color="#F9F9FA">
          <v-row class="ma-0 pa-0">
            <v-col cols="12" class="pa-0 ma-0 mt-n16">
              <app-back-button-component/>
            </v-col>
          </v-row>
          <v-card-title class="cmr-title px-0 d-flex justify-start">
            <span class="ml-6">{{ title }}</span>
          </v-card-title>
          <v-card-text class="pt-sans">
            <v-card
                tile flat color="transparent"
                class="mx-auto">
              <v-window v-model="index">
                <template v-for="(item, i) of questions">
                  <v-window-item :value="i" :key="i">
                    <v-card-text>
                      <template v-if="i === 0">
                        <v-row class="ma-0 pa-0">
                          <v-col cols="6" class="ma-0 py-0 pl-0">
                            <v-textarea rows="1" class="flex-fill" v-model="form.freeAnswer" placeholder="In der Dienststelle gibt es folgende krebserzeugende Produkte / Tätigkeiten:"></v-textarea>
                          </v-col>
                          <v-col cols="6" class="d-flex justify-end ma-0 pa-0">
                            <v-radio-group v-model="form.answers[i].answer">
                              <v-radio label="Nein" @change="countPoints(item.point)" :value="item.answers.no" class="flex-fill"></v-radio>
                            </v-radio-group>
                          </v-col>
                        </v-row>
                      </template>
                      <template v-else>
                        <v-row class="ma-0 pa-0">
                          <v-col cols="12" class="ma-0 pa-0">
                            <v-radio-group v-model="form.answers[i].answer" row>
                              <v-row class="ma-0 pa-0">
                                <v-col cols="6" class="ma-0 pa-0 d-flex justify-start">
                                  <v-radio label="Ja" :value="item.answers.yes"></v-radio>
                                </v-col>
                                <v-col cols="6" class="ma-0 pa-0 d-flex justify-end">
                                  <v-radio label="Nein" @change="countPoints(item.point)" :value="item.answers.no"></v-radio>
                                </v-col>
                              </v-row>
                            </v-radio-group>
                          </v-col>
                        </v-row>
                      </template>
                    </v-card-text>
                  </v-window-item>
                </template>
              </v-window>
              <v-card-actions>
                <v-btn text plain :ripple="false" :disabled="index === 0" @click="previous">
                  <v-icon>mdi-arrow-left-thin</v-icon>
                  <span>Zurück</span>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn :loading="loading" text plain :ripple="false" @click="next">
                  <span>{{ index === questions.length - 1 || finish ? 'Abschließen' : 'Weiter' }}</span>
                  <v-icon>mdi-arrow-right-thin</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
        top
        v-model="response.show"
        :min-width="$vuetify.breakpoint.width / 3"
        class="rounded"
        :timeout="5000"
        :color="response.status"
    >
      {{ response.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="$router.push({ name: 'CMR' })"
        >
          schließen
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import questions from '@/static/questions.json'
import axios from 'axios'

export default {
  name: "Questions",
  data () {
    return {
      loading: false,
      index: 0,
      items: questions.questions,
      finish: false,
      negative: null,
      response: {
        show: false,
        status: null,
        text: null
      },
      form: {
        freeAnswer: null,
        answers: [],
        points: 0
      }
    }
  },
  beforeMount() {
    this.form.personalization = this.$store.getters.GET_PERSONALIZATION
    this.form.green = this.$store.getters.GET_STATUSES.green
    this.items.forEach(() => {
      this.form.answers.push({
        answer: null
      })
    })
  },
  methods: {
    next () {
      if (this.finish) {
        this.send()
      } else {
        if (this.index < this.questions.length - 1) {
          this.index++
        } else {
          switch (true) {
            case this.form.points >= 1 && this.points < 3:
              this.form.yellow = `${this.$store.getters.GET_STATUSES.yellow}${this.negative}`
              break
            case this.form.points >= 3:
              this.form.red = `${this.$store.getters.GET_STATUSES.red}${this.negative}`
              break
          }
          this.send()
        }
      }
    },
    previous () {
      if (this.index > 0) {
        this.index--
        this.form.points -= this.questions[this.index].point
      }
    },
    countPoints (point) {
      if (point === 0) {
        this.finish = true
        return null
      } else {
        this.form.points += point
        this.negative += this.questions[this.index].ifNo
      }
    },
    send () {
      this.loading = true
      if (this.form.freeAnswer) {
        this.form.freeAnswer = `${this.questions[0].answers.yes} ${this.form.freeAnswer}. `
      }
      axios.post(`${process.env.VUE_APP_API_URL}/v1/cmr`, this.form)
          .then((response) => {
            this.response.show = true
            this.response.status = 'success'
            this.response.text = response.data.success
            this.loading = false
          })
          .catch((error) => {
            this.response.show = true
            this.response.status = 'error'
            this.response.text = error.response.data.error
            this.loading = false
          })
    }
  },
  computed: {
    title () {
      return this.questions[this.index].question
    },
    questions () {
      return this.items
    }
  }
}
</script>

<style scoped>

</style>